"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const validate_1 = require("../../compile/validate");
const code_1 = require("../code");
const util_1 = require("../../compile/util");
const additionalProperties_1 = require("./additionalProperties");
const def = {
  keyword: "properties",
  type: "object",
  schemaType: "object",
  code(cxt) {
    const {
      gen,
      schema,
      parentSchema,
      data,
      it
    } = cxt;
    if (it.opts.removeAdditional === "all" && parentSchema.additionalProperties === undefined) {
      additionalProperties_1.default.code(new validate_1.KeywordCxt(it, additionalProperties_1.default, "additionalProperties"));
    }
    const allProps = (0, code_1.allSchemaProperties)(schema);
    for (const prop of allProps) {
      it.definedProperties.add(prop);
    }
    if (it.opts.unevaluated && allProps.length && it.props !== true) {
      it.props = util_1.mergeEvaluated.props(gen, (0, util_1.toHash)(allProps), it.props);
    }
    const properties = allProps.filter(p => !(0, util_1.alwaysValidSchema)(it, schema[p]));
    if (properties.length === 0) return;
    const valid = gen.name("valid");
    for (const prop of properties) {
      if (hasDefault(prop)) {
        applyPropertySchema(prop);
      } else {
        gen.if((0, code_1.propertyInData)(gen, data, prop, it.opts.ownProperties));
        applyPropertySchema(prop);
        if (!it.allErrors) gen.else().var(valid, true);
        gen.endIf();
      }
      cxt.it.definedProperties.add(prop);
      cxt.ok(valid);
    }
    function hasDefault(prop) {
      return it.opts.useDefaults && !it.compositeRule && schema[prop].default !== undefined;
    }
    function applyPropertySchema(prop) {
      cxt.subschema({
        keyword: "properties",
        schemaProp: prop,
        dataProp: prop
      }, valid);
    }
  }
};
exports.default = def;
