"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const error = {
  message: "property name must be valid",
  params: ({
    params
  }) => (0, codegen_1._)`{propertyName: ${params.propertyName}}`
};
const def = {
  keyword: "propertyNames",
  type: "object",
  schemaType: ["object", "boolean"],
  error,
  code(cxt) {
    const {
      gen,
      schema,
      data,
      it
    } = cxt;
    if ((0, util_1.alwaysValidSchema)(it, schema)) return;
    const valid = gen.name("valid");
    gen.forIn("key", data, key => {
      cxt.setParams({
        propertyName: key
      });
      cxt.subschema({
        keyword: "propertyNames",
        data: key,
        dataTypes: ["string"],
        propertyName: key,
        compositeRule: true
      }, valid);
      gen.if((0, codegen_1.not)(valid), () => {
        cxt.error(true);
        if (!it.allErrors) gen.break();
      });
    });
    cxt.ok(valid);
  }
};
exports.default = def;
