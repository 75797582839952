"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const code_1 = require("../code");
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const error = {
  message: ({
    params: {
      missingProperty
    }
  }) => (0, codegen_1.str)`must have required property '${missingProperty}'`,
  params: ({
    params: {
      missingProperty
    }
  }) => (0, codegen_1._)`{missingProperty: ${missingProperty}}`
};
const def = {
  keyword: "required",
  type: "object",
  schemaType: "array",
  $data: true,
  error,
  code(cxt) {
    const {
      gen,
      schema,
      schemaCode,
      data,
      $data,
      it
    } = cxt;
    const {
      opts
    } = it;
    if (!$data && schema.length === 0) return;
    const useLoop = schema.length >= opts.loopRequired;
    if (it.allErrors) allErrorsMode();else exitOnErrorMode();
    if (opts.strictRequired) {
      const props = cxt.parentSchema.properties;
      const {
        definedProperties
      } = cxt.it;
      for (const requiredKey of schema) {
        if ((props === null || props === void 0 ? void 0 : props[requiredKey]) === undefined && !definedProperties.has(requiredKey)) {
          const schemaPath = it.schemaEnv.baseId + it.errSchemaPath;
          const msg = `required property "${requiredKey}" is not defined at "${schemaPath}" (strictRequired)`;
          (0, util_1.checkStrictMode)(it, msg, it.opts.strictRequired);
        }
      }
    }
    function allErrorsMode() {
      if (useLoop || $data) {
        cxt.block$data(codegen_1.nil, loopAllRequired);
      } else {
        for (const prop of schema) {
          (0, code_1.checkReportMissingProp)(cxt, prop);
        }
      }
    }
    function exitOnErrorMode() {
      const missing = gen.let("missing");
      if (useLoop || $data) {
        const valid = gen.let("valid", true);
        cxt.block$data(valid, () => loopUntilMissing(missing, valid));
        cxt.ok(valid);
      } else {
        gen.if((0, code_1.checkMissingProp)(cxt, schema, missing));
        (0, code_1.reportMissingProp)(cxt, missing);
        gen.else();
      }
    }
    function loopAllRequired() {
      gen.forOf("prop", schemaCode, prop => {
        cxt.setParams({
          missingProperty: prop
        });
        gen.if((0, code_1.noPropertyInData)(gen, data, prop, opts.ownProperties), () => cxt.error());
      });
    }
    function loopUntilMissing(missing, valid) {
      cxt.setParams({
        missingProperty: missing
      });
      gen.forOf(missing, schemaCode, () => {
        gen.assign(valid, (0, code_1.propertyInData)(gen, data, missing, opts.ownProperties));
        gen.if((0, codegen_1.not)(valid), () => {
          cxt.error();
          gen.break();
        });
      }, codegen_1.nil);
    }
  }
};
exports.default = def;
