"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const util_1 = require("../../compile/util");
const def = {
  keyword: "not",
  schemaType: ["object", "boolean"],
  trackErrors: true,
  code(cxt) {
    const {
      gen,
      schema,
      it
    } = cxt;
    if ((0, util_1.alwaysValidSchema)(it, schema)) {
      cxt.fail();
      return;
    }
    const valid = gen.name("valid");
    cxt.subschema({
      keyword: "not",
      compositeRule: true,
      createErrors: false,
      allErrors: false
    }, valid);
    cxt.failResult(valid, () => cxt.reset(), () => cxt.error());
  },
  error: {
    message: "must NOT be valid"
  }
};
exports.default = def;
