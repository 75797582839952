"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const code_1 = require("../code");
const codegen_1 = require("../../compile/codegen");
const error = {
  message: ({
    schemaCode
  }) => (0, codegen_1.str)`must match pattern "${schemaCode}"`,
  params: ({
    schemaCode
  }) => (0, codegen_1._)`{pattern: ${schemaCode}}`
};
const def = {
  keyword: "pattern",
  type: "string",
  schemaType: "string",
  $data: true,
  error,
  code(cxt) {
    const {
      data,
      $data,
      schema,
      schemaCode,
      it
    } = cxt;
    // TODO regexp should be wrapped in try/catchs
    const u = it.opts.unicodeRegExp ? "u" : "";
    const regExp = $data ? (0, codegen_1._)`(new RegExp(${schemaCode}, ${u}))` : (0, code_1.usePattern)(cxt, schema);
    cxt.fail$data((0, codegen_1._)`!${regExp}.test(${data})`);
  }
};
exports.default = def;
