import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ProgressState } from '@core/models';
import { ProgressDialogComponent } from '@shared/dialogs';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  open<TDialog, TData>(
    component: ComponentType<TDialog>,
    config?: MatDialogConfig<TData> | undefined): MatDialogRef<TDialog, unknown> {

    return this.dialog.open(component, config);
  }

  openProgress(title: string, description: string, initialMessage: string)
    : ProgressState {

    const progress = new ProgressState(initialMessage);
    this.open(ProgressDialogComponent, {
      width: '550px',
      data: {
        title: title,
        description: description,
        progressState: progress,
      }
    });
    return progress;
  }

  async show<TDialog, TData, TResponse>(
    component: ComponentType<TDialog>,
    config?: MatDialogConfig<TData> | undefined): Promise<TResponse | undefined> {

    const ref = this.dialog.open<TDialog, TData, TResponse>(component, config);
    return await firstValueFrom(ref.afterClosed());
  }
}
