"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const id_1 = require("./id");
const ref_1 = require("./ref");
const core = ["$schema", "$id", "$defs", "$vocabulary", {
  keyword: "$comment"
}, "definitions", id_1.default, ref_1.default];
exports.default = core;
