"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const error = {
  message({
    keyword,
    schemaCode
  }) {
    const comp = keyword === "maxItems" ? "more" : "fewer";
    return (0, codegen_1.str)`must NOT have ${comp} than ${schemaCode} items`;
  },
  params: ({
    schemaCode
  }) => (0, codegen_1._)`{limit: ${schemaCode}}`
};
const def = {
  keyword: ["maxItems", "minItems"],
  type: "array",
  schemaType: "number",
  $data: true,
  error,
  code(cxt) {
    const {
      keyword,
      data,
      schemaCode
    } = cxt;
    const op = keyword === "maxItems" ? codegen_1.operators.GT : codegen_1.operators.LT;
    cxt.fail$data((0, codegen_1._)`${data}.length ${op} ${schemaCode}`);
  }
};
exports.default = def;
