"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRules = exports.isJSONType = void 0;
const _jsonTypes = ["string", "number", "integer", "boolean", "null", "object", "array"];
const jsonTypes = new Set(_jsonTypes);
function isJSONType(x) {
  return typeof x == "string" && jsonTypes.has(x);
}
exports.isJSONType = isJSONType;
function getRules() {
  const groups = {
    number: {
      type: "number",
      rules: []
    },
    string: {
      type: "string",
      rules: []
    },
    array: {
      type: "array",
      rules: []
    },
    object: {
      type: "object",
      rules: []
    }
  };
  return {
    types: {
      ...groups,
      integer: true,
      boolean: true,
      null: true
    },
    rules: [{
      rules: []
    }, groups.number, groups.string, groups.array, groups.object],
    post: {
      rules: []
    },
    all: {},
    keywords: {}
  };
}
exports.getRules = getRules;
