"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const def = {
  keyword: "id",
  code() {
    throw new Error('NOT SUPPORTED: keyword "id", use "$id" for schema ID');
  }
};
exports.default = def;
