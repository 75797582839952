"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const error = {
  message: "must match exactly one schema in oneOf",
  params: ({
    params
  }) => (0, codegen_1._)`{passingSchemas: ${params.passing}}`
};
const def = {
  keyword: "oneOf",
  schemaType: "array",
  trackErrors: true,
  error,
  code(cxt) {
    const {
      gen,
      schema,
      parentSchema,
      it
    } = cxt;
    /* istanbul ignore if */
    if (!Array.isArray(schema)) throw new Error("ajv implementation error");
    if (it.opts.discriminator && parentSchema.discriminator) return;
    const schArr = schema;
    const valid = gen.let("valid", false);
    const passing = gen.let("passing", null);
    const schValid = gen.name("_valid");
    cxt.setParams({
      passing
    });
    // TODO possibly fail straight away (with warning or exception) if there are two empty always valid schemas
    gen.block(validateOneOf);
    cxt.result(valid, () => cxt.reset(), () => cxt.error(true));
    function validateOneOf() {
      schArr.forEach((sch, i) => {
        let schCxt;
        if ((0, util_1.alwaysValidSchema)(it, sch)) {
          gen.var(schValid, true);
        } else {
          schCxt = cxt.subschema({
            keyword: "oneOf",
            schemaProp: i,
            compositeRule: true
          }, schValid);
        }
        if (i > 0) {
          gen.if((0, codegen_1._)`${schValid} && ${valid}`).assign(valid, false).assign(passing, (0, codegen_1._)`[${passing}, ${i}]`).else();
        }
        gen.if(schValid, () => {
          gen.assign(valid, true);
          gen.assign(passing, i);
          if (schCxt) cxt.mergeEvaluated(schCxt, codegen_1.Name);
        });
      });
    }
  }
};
exports.default = def;
