import { Injectable } from '@angular/core';
import pLimit from 'p-limit';

import { Document } from '@core/models';
import { Library, LibraryService } from '@core/services';
import { DefaultDocuments } from '@shared/reference';

export class PopulateResult {
  constructor(public recordTypes: string[]) { }
}

export interface DocumentItem {
  id: string;
  libraryId: Library;
  document?: Document;
}

@Injectable({
  providedIn: 'root'
})
export class PopulateService {
  private isInitialised = false;

  constructor(
    private readonly library: LibraryService,
  ) { }

  async init(): Promise<boolean> {
    if (!this.isInitialised) {
      try {
        const isLoaded = await this.loadFromLibrary();
        this.isInitialised = isLoaded;
        return isLoaded;
      } catch (e) {
        console.error(e);
      }
    }
    return this.isInitialised;
  }

  getDefaultTemplateId(): string {
    return 'smartAuditV2';
  }

  async loadFromLibrary(): Promise<boolean> {
    const limit = pLimit(10);
    const promises: Promise<Document>[] = [];
    for (const item of this.documentCache) {
      promises.push(
        limit(() => this.library
          .getItem(item.libraryId)
          .then(d => {
            item.document = d;
            return d;
          })));
    }

    try {
      await Promise.all(promises);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private documentCache: DocumentItem[] = [
    {
      id: DefaultDocuments.defaultDashboardTranslationEnGB,
      libraryId: Library.defaultDashboardTranslationEnGB,
    },
    {
      id: DefaultDocuments.defaultDashboardTranslationHiIN,
      libraryId: Library.defaultDashboardTranslationHiIN,
    },
    {
      id: DefaultDocuments.defaultDashboard,
      libraryId: Library.defaultDashboard,
    },
    {
      id: DefaultDocuments.defaultEmailTemplate,
      libraryId: Library.defaultEmailTemplate,
    },
    {
      id: DefaultDocuments.defaultGlobalTranslationEnGB,
      libraryId: Library.defaultGlobalTranslationEnGB,
    },
    {
      id: DefaultDocuments.defaultGlobalTranslationEsES,
      libraryId: Library.defaultGlobalTranslationEsES,
    },
    {
      id: DefaultDocuments.defaultGlobalTranslationHiIN,
      libraryId: Library.defaultGlobalTranslationHiIN,
    },
    {
      id: DefaultDocuments.defaultRecordTypeTranslationEnGB,
      libraryId: Library.defaultRecordTypeTranslationEnGB,
    },
    {
      id: DefaultDocuments.defaultRecordTypeTranslationhiIN,
      libraryId: Library.defaultRecordTypeTranslationhiIN,
    },
    {
      id: DefaultDocuments.defaultRecordType,
      libraryId: Library.defaultRecordType,
    },
    {
      id: DefaultDocuments.defaultReferenceDataTranslationEnGB,
      libraryId: Library.defaultReferenceDataTranslationEnGB,
    },
    {
      id: DefaultDocuments.defaultReferenceDataTranslationEsES,
      libraryId: Library.defaultReferenceDataTranslationEsES,
    },
    {
      id: DefaultDocuments.defaultReferenceDataTranslationHiIN,
      libraryId: Library.defaultReferenceDataTranslationHiIN,
    },
    {
      id: DefaultDocuments.defaultReferenceData,
      libraryId: Library.defaultReferenceData,
    },
    {
      id: DefaultDocuments.defaultTermsOfUse,
      libraryId: Library.defaultTermsOfUse,
    },
    {
      id: DefaultDocuments.defaultTranslation,
      libraryId: Library.defaultTranslation,
    },
    {
      id: DefaultDocuments.defaultUserData,
      libraryId: Library.defaultUserData,
    },
    {
      id: DefaultDocuments.defaultWorkspaceData,
      libraryId: Library.defaultWorkspaceData,
    },
  ];

  getDefaultDocument(id: string): Document | undefined {
    const item = this.documentCache.find(item => item.id === id);
    if (item) {
      return item.document;
    }
    return undefined;
  }

  getDefaultDocumentItems(): DocumentItem[] {
    return this.documentCache;
  }
}
