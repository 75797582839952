import { Injectable, signal, effect, computed } from '@angular/core';

import { AuthService } from './auth.service';
import { AuthApiService } from './auth-api.service';
import { Claims, WorkspaceClaim } from '@core/models';
import { LevelPolicy } from '@shared/reference';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  private readonly emptyClaims: Claims = {
    id: '',
    email: '',
    name: '',
    policy: '',
    workspaces: [],
  };

  readonly claims = signal<Claims>(this.emptyClaims);

  readonly id = computed(() => this.claims().id);
  readonly name = computed(() => this.claims().name);
  readonly email = computed(() => this.claims().email);

  readonly isSuperAdmin = computed(() => this.claims().policy === LevelPolicy.superadmin);
  readonly isAdmin = computed(() => this.claims().policy === LevelPolicy.admin);
  readonly isStandard = computed(() => this.claims().policy === LevelPolicy.standard);
  readonly isReadOnly = computed(() => this.claims().policy === LevelPolicy.readonly);
  readonly hasNoPolicy = computed(() =>
    this.claims().policy !== LevelPolicy.superadmin
    && this.claims().policy !== LevelPolicy.admin
    && this.claims().policy !== LevelPolicy.standard
    && this.claims().policy !== LevelPolicy.readonly
  );

  readonly workspaces = computed(() => this.claims().workspaces);

  readonly isAuthenticated = computed(() => !!this.claims().id);

  constructor(
    private authApiService: AuthApiService,
    private authService: AuthService) {
      effect(() => {
        if (this.authService.isAuthenticated()) {
          this.authApiService.me()
            .then(me => this.claims.set(me))
            .catch(() => this.claims.set(this.emptyClaims));
        } else {
          this.claims.set(this.emptyClaims);
        }
      });
    }

  getWorkspacePolicy(workspaceId: string, workspaceClaims: WorkspaceClaim[]): string {
    const workspace = workspaceClaims.find(w => w.id === workspaceId);
    return workspace ? workspace.workspacePolicy : '';
  }

  requireAuthentication(): void {
    if (!this.isAuthenticated()) {
      this.authService.login();
    }
  }

  async refreshClaims(): Promise<void> {
    await this.authService.refresh();
  }
}
