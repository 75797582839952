"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateUnion = exports.validateArray = exports.usePattern = exports.callValidateCode = exports.schemaProperties = exports.allSchemaProperties = exports.noPropertyInData = exports.propertyInData = exports.isOwnProperty = exports.hasPropFunc = exports.reportMissingProp = exports.checkMissingProp = exports.checkReportMissingProp = void 0;
const codegen_1 = require("../compile/codegen");
const util_1 = require("../compile/util");
const names_1 = require("../compile/names");
const util_2 = require("../compile/util");
function checkReportMissingProp(cxt, prop) {
  const {
    gen,
    data,
    it
  } = cxt;
  gen.if(noPropertyInData(gen, data, prop, it.opts.ownProperties), () => {
    cxt.setParams({
      missingProperty: (0, codegen_1._)`${prop}`
    }, true);
    cxt.error();
  });
}
exports.checkReportMissingProp = checkReportMissingProp;
function checkMissingProp({
  gen,
  data,
  it: {
    opts
  }
}, properties, missing) {
  return (0, codegen_1.or)(...properties.map(prop => (0, codegen_1.and)(noPropertyInData(gen, data, prop, opts.ownProperties), (0, codegen_1._)`${missing} = ${prop}`)));
}
exports.checkMissingProp = checkMissingProp;
function reportMissingProp(cxt, missing) {
  cxt.setParams({
    missingProperty: missing
  }, true);
  cxt.error();
}
exports.reportMissingProp = reportMissingProp;
function hasPropFunc(gen) {
  return gen.scopeValue("func", {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    ref: Object.prototype.hasOwnProperty,
    code: (0, codegen_1._)`Object.prototype.hasOwnProperty`
  });
}
exports.hasPropFunc = hasPropFunc;
function isOwnProperty(gen, data, property) {
  return (0, codegen_1._)`${hasPropFunc(gen)}.call(${data}, ${property})`;
}
exports.isOwnProperty = isOwnProperty;
function propertyInData(gen, data, property, ownProperties) {
  const cond = (0, codegen_1._)`${data}${(0, codegen_1.getProperty)(property)} !== undefined`;
  return ownProperties ? (0, codegen_1._)`${cond} && ${isOwnProperty(gen, data, property)}` : cond;
}
exports.propertyInData = propertyInData;
function noPropertyInData(gen, data, property, ownProperties) {
  const cond = (0, codegen_1._)`${data}${(0, codegen_1.getProperty)(property)} === undefined`;
  return ownProperties ? (0, codegen_1.or)(cond, (0, codegen_1.not)(isOwnProperty(gen, data, property))) : cond;
}
exports.noPropertyInData = noPropertyInData;
function allSchemaProperties(schemaMap) {
  return schemaMap ? Object.keys(schemaMap).filter(p => p !== "__proto__") : [];
}
exports.allSchemaProperties = allSchemaProperties;
function schemaProperties(it, schemaMap) {
  return allSchemaProperties(schemaMap).filter(p => !(0, util_1.alwaysValidSchema)(it, schemaMap[p]));
}
exports.schemaProperties = schemaProperties;
function callValidateCode({
  schemaCode,
  data,
  it: {
    gen,
    topSchemaRef,
    schemaPath,
    errorPath
  },
  it
}, func, context, passSchema) {
  const dataAndSchema = passSchema ? (0, codegen_1._)`${schemaCode}, ${data}, ${topSchemaRef}${schemaPath}` : data;
  const valCxt = [[names_1.default.instancePath, (0, codegen_1.strConcat)(names_1.default.instancePath, errorPath)], [names_1.default.parentData, it.parentData], [names_1.default.parentDataProperty, it.parentDataProperty], [names_1.default.rootData, names_1.default.rootData]];
  if (it.opts.dynamicRef) valCxt.push([names_1.default.dynamicAnchors, names_1.default.dynamicAnchors]);
  const args = (0, codegen_1._)`${dataAndSchema}, ${gen.object(...valCxt)}`;
  return context !== codegen_1.nil ? (0, codegen_1._)`${func}.call(${context}, ${args})` : (0, codegen_1._)`${func}(${args})`;
}
exports.callValidateCode = callValidateCode;
const newRegExp = (0, codegen_1._)`new RegExp`;
function usePattern({
  gen,
  it: {
    opts
  }
}, pattern) {
  const u = opts.unicodeRegExp ? "u" : "";
  const {
    regExp
  } = opts.code;
  const rx = regExp(pattern, u);
  return gen.scopeValue("pattern", {
    key: rx.toString(),
    ref: rx,
    code: (0, codegen_1._)`${regExp.code === "new RegExp" ? newRegExp : (0, util_2.useFunc)(gen, regExp)}(${pattern}, ${u})`
  });
}
exports.usePattern = usePattern;
function validateArray(cxt) {
  const {
    gen,
    data,
    keyword,
    it
  } = cxt;
  const valid = gen.name("valid");
  if (it.allErrors) {
    const validArr = gen.let("valid", true);
    validateItems(() => gen.assign(validArr, false));
    return validArr;
  }
  gen.var(valid, true);
  validateItems(() => gen.break());
  return valid;
  function validateItems(notValid) {
    const len = gen.const("len", (0, codegen_1._)`${data}.length`);
    gen.forRange("i", 0, len, i => {
      cxt.subschema({
        keyword,
        dataProp: i,
        dataPropType: util_1.Type.Num
      }, valid);
      gen.if((0, codegen_1.not)(valid), notValid);
    });
  }
}
exports.validateArray = validateArray;
function validateUnion(cxt) {
  const {
    gen,
    schema,
    keyword,
    it
  } = cxt;
  /* istanbul ignore if */
  if (!Array.isArray(schema)) throw new Error("ajv implementation error");
  const alwaysValid = schema.some(sch => (0, util_1.alwaysValidSchema)(it, sch));
  if (alwaysValid && !it.opts.unevaluated) return;
  const valid = gen.let("valid", false);
  const schValid = gen.name("_valid");
  gen.block(() => schema.forEach((_sch, i) => {
    const schCxt = cxt.subschema({
      keyword,
      schemaProp: i,
      compositeRule: true
    }, schValid);
    gen.assign(valid, (0, codegen_1._)`${valid} || ${schValid}`);
    const merged = cxt.mergeValidEvaluated(schCxt, schValid);
    // can short-circuit if `unevaluatedProperties/Items` not supported (opts.unevaluated !== true)
    // or if all properties and items were evaluated (it.props === true && it.items === true)
    if (!merged) gen.if((0, codegen_1.not)(valid));
  }));
  cxt.result(valid, () => cxt.reset(), () => cxt.error(true));
}
exports.validateUnion = validateUnion;
