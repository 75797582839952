"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("./codegen");
const names = {
  // validation function arguments
  data: new codegen_1.Name("data"),
  // data passed to validation function
  // args passed from referencing schema
  valCxt: new codegen_1.Name("valCxt"),
  // validation/data context - should not be used directly, it is destructured to the names below
  instancePath: new codegen_1.Name("instancePath"),
  parentData: new codegen_1.Name("parentData"),
  parentDataProperty: new codegen_1.Name("parentDataProperty"),
  rootData: new codegen_1.Name("rootData"),
  // root data - same as the data passed to the first/top validation function
  dynamicAnchors: new codegen_1.Name("dynamicAnchors"),
  // used to support recursiveRef and dynamicRef
  // function scoped variables
  vErrors: new codegen_1.Name("vErrors"),
  // null or array of validation errors
  errors: new codegen_1.Name("errors"),
  // counter of validation errors
  this: new codegen_1.Name("this"),
  // "globals"
  self: new codegen_1.Name("self"),
  scope: new codegen_1.Name("scope"),
  // JTD serialize/parse name for JSON string and position
  json: new codegen_1.Name("json"),
  jsonPos: new codegen_1.Name("jsonPos"),
  jsonLen: new codegen_1.Name("jsonLen"),
  jsonPart: new codegen_1.Name("jsonPart")
};
exports.default = names;
