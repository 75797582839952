"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const error = {
  message: ({
    schemaCode
  }) => (0, codegen_1.str)`must be multiple of ${schemaCode}`,
  params: ({
    schemaCode
  }) => (0, codegen_1._)`{multipleOf: ${schemaCode}}`
};
const def = {
  keyword: "multipleOf",
  type: "number",
  schemaType: "number",
  $data: true,
  error,
  code(cxt) {
    const {
      gen,
      data,
      schemaCode,
      it
    } = cxt;
    // const bdt = bad$DataType(schemaCode, <string>def.schemaType, $data)
    const prec = it.opts.multipleOfPrecision;
    const res = gen.let("res");
    const invalid = prec ? (0, codegen_1._)`Math.abs(Math.round(${res}) - ${res}) > 1e-${prec}` : (0, codegen_1._)`${res} !== parseInt(${res})`;
    cxt.fail$data((0, codegen_1._)`(${schemaCode} === 0 || (${res} = ${data}/${schemaCode}, ${invalid}))`);
  }
};
exports.default = def;
