"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const ops = codegen_1.operators;
const KWDs = {
  maximum: {
    okStr: "<=",
    ok: ops.LTE,
    fail: ops.GT
  },
  minimum: {
    okStr: ">=",
    ok: ops.GTE,
    fail: ops.LT
  },
  exclusiveMaximum: {
    okStr: "<",
    ok: ops.LT,
    fail: ops.GTE
  },
  exclusiveMinimum: {
    okStr: ">",
    ok: ops.GT,
    fail: ops.LTE
  }
};
const error = {
  message: ({
    keyword,
    schemaCode
  }) => (0, codegen_1.str)`must be ${KWDs[keyword].okStr} ${schemaCode}`,
  params: ({
    keyword,
    schemaCode
  }) => (0, codegen_1._)`{comparison: ${KWDs[keyword].okStr}, limit: ${schemaCode}}`
};
const def = {
  keyword: Object.keys(KWDs),
  type: "number",
  schemaType: "number",
  $data: true,
  error,
  code(cxt) {
    const {
      keyword,
      data,
      schemaCode
    } = cxt;
    cxt.fail$data((0, codegen_1._)`${data} ${KWDs[keyword].fail} ${schemaCode} || isNaN(${data})`);
  }
};
exports.default = def;
