import { DateTime } from 'luxon';
import { Document } from '@core/models';
import { RecordFields, RecordLink, RecordLinks } from './record-content';

export class Person {
  constructor(public id: string,
              public fullName: string,
              public title: string,
              public email: string,
              public startDate: DateTime,
              public creator?: RecordLink,
              public reportsTo?: RecordLink,
              public basedAt?: RecordLink) {}

  static fromDocument(record: Document): Person {
    const fields = record.content.fields;
    const links = record.content.links;

    return new Person(
      record.id,
      fields.fullName,
      fields.title,
      fields.email,
      DateTime.fromISO(fields.startDate),
      this.firstLink(links.createdBy as RecordLink[]),
      this.firstLink(links.reportsTo as RecordLink[]),
      this.firstLink(links.basedAt as RecordLink[]));
  }

  toDocument(): Document {
    const fields: RecordFields = {
      subType: 'person',
      state: 'active',
      createdAt: DateTime.now().toISO(),
      modifiedAt: DateTime.now().toISO(),
      fullName: this.fullName,
      email: this.email,
      title: this.title,
      employeeId: '00000000',
      startDate: this.startDate.toISO(),
    };
    const links: RecordLinks = {};
    if (this.creator) {
      links['owners'] = [ this.creator ];
      links['createdBy'] = [ this.creator ];
      links['modifiedBy'] = [ this.creator ];
    }
    if (this.reportsTo) {
      links['reportsTo'] = [ this.reportsTo ];
    }
    if (this.basedAt) {
      links['basedAt'] = [ this.basedAt ];
    }

    return {
      id: this.id,
      documentId: this.id,
      documentType: 'record',
      content: {
        recordType: 'person',
        fields,
        links,
      },
    };
  }

  private static firstLink(links: RecordLink[]): RecordLink | undefined {
    return links && links.length > 0 ? links[0] : undefined;
  }
}
