"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const equal_1 = require("../../runtime/equal");
const error = {
  message: "must be equal to constant",
  params: ({
    schemaCode
  }) => (0, codegen_1._)`{allowedValue: ${schemaCode}}`
};
const def = {
  keyword: "const",
  $data: true,
  error,
  code(cxt) {
    const {
      gen,
      data,
      $data,
      schemaCode,
      schema
    } = cxt;
    if ($data || schema && typeof schema == "object") {
      cxt.fail$data((0, codegen_1._)`!${(0, util_1.useFunc)(gen, equal_1.default)}(${data}, ${schemaCode})`);
    } else {
      cxt.fail((0, codegen_1._)`${schema} !== ${data}`);
    }
  }
};
exports.default = def;
