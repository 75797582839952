"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.regexpCode = exports.getEsmExportName = exports.getProperty = exports.safeStringify = exports.stringify = exports.strConcat = exports.addCodeArg = exports.str = exports._ = exports.nil = exports._Code = exports.Name = exports.IDENTIFIER = exports._CodeOrName = void 0;
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class _CodeOrName {}
exports._CodeOrName = _CodeOrName;
exports.IDENTIFIER = /^[a-z$_][a-z$_0-9]*$/i;
class Name extends _CodeOrName {
  constructor(s) {
    super();
    if (!exports.IDENTIFIER.test(s)) throw new Error("CodeGen: name must be a valid identifier");
    this.str = s;
  }
  toString() {
    return this.str;
  }
  emptyStr() {
    return false;
  }
  get names() {
    return {
      [this.str]: 1
    };
  }
}
exports.Name = Name;
class _Code extends _CodeOrName {
  constructor(code) {
    super();
    this._items = typeof code === "string" ? [code] : code;
  }
  toString() {
    return this.str;
  }
  emptyStr() {
    if (this._items.length > 1) return false;
    const item = this._items[0];
    return item === "" || item === '""';
  }
  get str() {
    var _a;
    return (_a = this._str) !== null && _a !== void 0 ? _a : this._str = this._items.reduce((s, c) => `${s}${c}`, "");
  }
  get names() {
    var _a;
    return (_a = this._names) !== null && _a !== void 0 ? _a : this._names = this._items.reduce((names, c) => {
      if (c instanceof Name) names[c.str] = (names[c.str] || 0) + 1;
      return names;
    }, {});
  }
}
exports._Code = _Code;
exports.nil = new _Code("");
function _(strs, ...args) {
  const code = [strs[0]];
  let i = 0;
  while (i < args.length) {
    addCodeArg(code, args[i]);
    code.push(strs[++i]);
  }
  return new _Code(code);
}
exports._ = _;
const plus = new _Code("+");
function str(strs, ...args) {
  const expr = [safeStringify(strs[0])];
  let i = 0;
  while (i < args.length) {
    expr.push(plus);
    addCodeArg(expr, args[i]);
    expr.push(plus, safeStringify(strs[++i]));
  }
  optimize(expr);
  return new _Code(expr);
}
exports.str = str;
function addCodeArg(code, arg) {
  if (arg instanceof _Code) code.push(...arg._items);else if (arg instanceof Name) code.push(arg);else code.push(interpolate(arg));
}
exports.addCodeArg = addCodeArg;
function optimize(expr) {
  let i = 1;
  while (i < expr.length - 1) {
    if (expr[i] === plus) {
      const res = mergeExprItems(expr[i - 1], expr[i + 1]);
      if (res !== undefined) {
        expr.splice(i - 1, 3, res);
        continue;
      }
      expr[i++] = "+";
    }
    i++;
  }
}
function mergeExprItems(a, b) {
  if (b === '""') return a;
  if (a === '""') return b;
  if (typeof a == "string") {
    if (b instanceof Name || a[a.length - 1] !== '"') return;
    if (typeof b != "string") return `${a.slice(0, -1)}${b}"`;
    if (b[0] === '"') return a.slice(0, -1) + b.slice(1);
    return;
  }
  if (typeof b == "string" && b[0] === '"' && !(a instanceof Name)) return `"${a}${b.slice(1)}`;
  return;
}
function strConcat(c1, c2) {
  return c2.emptyStr() ? c1 : c1.emptyStr() ? c2 : str`${c1}${c2}`;
}
exports.strConcat = strConcat;
// TODO do not allow arrays here
function interpolate(x) {
  return typeof x == "number" || typeof x == "boolean" || x === null ? x : safeStringify(Array.isArray(x) ? x.join(",") : x);
}
function stringify(x) {
  return new _Code(safeStringify(x));
}
exports.stringify = stringify;
function safeStringify(x) {
  return JSON.stringify(x).replace(/\u2028/g, "\\u2028").replace(/\u2029/g, "\\u2029");
}
exports.safeStringify = safeStringify;
function getProperty(key) {
  return typeof key == "string" && exports.IDENTIFIER.test(key) ? new _Code(`.${key}`) : _`[${key}]`;
}
exports.getProperty = getProperty;
//Does best effort to format the name properly
function getEsmExportName(key) {
  if (typeof key == "string" && exports.IDENTIFIER.test(key)) {
    return new _Code(`${key}`);
  }
  throw new Error(`CodeGen: invalid export name: ${key}, use explicit $id name mapping`);
}
exports.getEsmExportName = getEsmExportName;
function regexpCode(rx) {
  return new _Code(rx.toString());
}
exports.regexpCode = regexpCode;
