import { Injectable } from '@angular/core';

import { Member, MemberDocument, ProgressIterable, ProgressUpdate } from '@core/models';
import { DocumentTypes } from '@shared/reference';
import { AdminApiService } from './admin-api.service';
import jsonpatch from 'json-patch';

export class CreateMissingMembersResult {
  constructor(public members: Member[]) { }
}

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(
    private readonly adminApiService: AdminApiService,
  ) { }

  /**
   * Convert a member document to a member
   * @param member Member document to convert
   * @returns Member
   */
  convertMemberDocumentToMember(member: MemberDocument): Member {
    return {
      memberId: member.id,
      objectId: member.content.objectId,
      name: member.content.name,
      email: member.content.email,
      platformPolicy: member.content.platformPolicy,
      isServiceMember: member.content.isServiceMember,
      secret: member.content.secret,
      tags: member.content.tags,
    };
  }

  /**
   * Convert a member to a member document with the specified platform policy (optional)
   * @param member Member to convert
   * @returns Member document
   * @remarks
   * The member is not connected to a identity provider, not have a password and not enabled.
   */
  converMemberToMemberDocument(member: Member): MemberDocument {
    return {
      id: member.memberId,
      documentType: DocumentTypes.member,
      content: {
        objectId: member.objectId,
        name: member.name,
        email: member.email,
        isEnabled: true,
        hasPassword: member.isServiceMember || false,
        isServiceMember: member.isServiceMember || false,
        secret: member.secret,
        platformPolicy: member.platformPolicy,
        tags: member.tags,
      },
    };
  }

  /**
   * Create missing members in the platform
   * @param members Members to create
   * @param platformPolicy Platform policy to assign to the members
   * @returns Created members
   * @throws Error if member creation fails
   * @remarks
   * This method will create members that do not exist in the platform. The member id will be
   * updated with the id of the created member.
   */
  async *createMissingMembers(members: Member[]): ProgressIterable<CreateMissingMembersResult> {
    const createdMembers: Member[] = [];
    const [memberDocuments, _] = await this.adminApiService.getMembers();

    const multiplier = 100 / members.length;
    for (const member of members) {
      const memberDocument = memberDocuments.find(m => m.id === member.memberId);
      if (!memberDocument) {
        const memberDocument = this.converMemberToMemberDocument(member);

        yield new ProgressUpdate(
          multiplier * members.indexOf(member),
          `Creating member ${member.name}`);

        await this.adminApiService
          .createMember(memberDocument)
          .then((m) => {
            member.memberId = m.id;
            return m;
          });
        createdMembers.push(member);
      }
    }

    yield new CreateMissingMembersResult(createdMembers);
  }

  /**
   * Create a member in the platform
   * @param member Member to create
   */
  async createMember(member: Member): Promise<void> {
    const memberDocument = this.converMemberToMemberDocument(member);
    await this.adminApiService.createMember(memberDocument);
  }

  /**
   * Get a member in the platform
   * @param memberId Member id to get
   * @returns Member
   */
  async getMember(memberId: string): Promise<Member> {
    const memberDocument = await this.adminApiService.getMember(memberId);
    return this.convertMemberDocumentToMember(memberDocument);
  }

  /**
   * Patch a member in the platform
   * @param instructions Instructions to update the member
   * @param memberId Member id to update
   * @returns Updated member
   */
  async patchMember(instructions: jsonpatch.OpPatch[], memberId: string): Promise<Member> {
    const memberDocument = await this.adminApiService.patchMember(instructions, memberId);
    return this.convertMemberDocumentToMember(memberDocument);
  }

  /**
   * Get all members in the platform
   * @returns All members in the platform
   */
  async getMembers(limit?: number, continuationToken?: string): Promise<[Member[], string]> {
    return await this.adminApiService
      .getMembers({limit, continuationToken})
      .then(([members, continuationToken]) =>
        [members.map(m => this.convertMemberDocumentToMember(m)), continuationToken]);
  }
}
