"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const dataType_1 = require("../../compile/validate/dataType");
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const equal_1 = require("../../runtime/equal");
const error = {
  message: ({
    params: {
      i,
      j
    }
  }) => (0, codegen_1.str)`must NOT have duplicate items (items ## ${j} and ${i} are identical)`,
  params: ({
    params: {
      i,
      j
    }
  }) => (0, codegen_1._)`{i: ${i}, j: ${j}}`
};
const def = {
  keyword: "uniqueItems",
  type: "array",
  schemaType: "boolean",
  $data: true,
  error,
  code(cxt) {
    const {
      gen,
      data,
      $data,
      schema,
      parentSchema,
      schemaCode,
      it
    } = cxt;
    if (!$data && !schema) return;
    const valid = gen.let("valid");
    const itemTypes = parentSchema.items ? (0, dataType_1.getSchemaTypes)(parentSchema.items) : [];
    cxt.block$data(valid, validateUniqueItems, (0, codegen_1._)`${schemaCode} === false`);
    cxt.ok(valid);
    function validateUniqueItems() {
      const i = gen.let("i", (0, codegen_1._)`${data}.length`);
      const j = gen.let("j");
      cxt.setParams({
        i,
        j
      });
      gen.assign(valid, true);
      gen.if((0, codegen_1._)`${i} > 1`, () => (canOptimize() ? loopN : loopN2)(i, j));
    }
    function canOptimize() {
      return itemTypes.length > 0 && !itemTypes.some(t => t === "object" || t === "array");
    }
    function loopN(i, j) {
      const item = gen.name("item");
      const wrongType = (0, dataType_1.checkDataTypes)(itemTypes, item, it.opts.strictNumbers, dataType_1.DataType.Wrong);
      const indices = gen.const("indices", (0, codegen_1._)`{}`);
      gen.for((0, codegen_1._)`;${i}--;`, () => {
        gen.let(item, (0, codegen_1._)`${data}[${i}]`);
        gen.if(wrongType, (0, codegen_1._)`continue`);
        if (itemTypes.length > 1) gen.if((0, codegen_1._)`typeof ${item} == "string"`, (0, codegen_1._)`${item} += "_"`);
        gen.if((0, codegen_1._)`typeof ${indices}[${item}] == "number"`, () => {
          gen.assign(j, (0, codegen_1._)`${indices}[${item}]`);
          cxt.error();
          gen.assign(valid, false).break();
        }).code((0, codegen_1._)`${indices}[${item}] = ${i}`);
      });
    }
    function loopN2(i, j) {
      const eql = (0, util_1.useFunc)(gen, equal_1.default);
      const outer = gen.name("outer");
      gen.label(outer).for((0, codegen_1._)`;${i}--;`, () => gen.for((0, codegen_1._)`${j} = ${i}; ${j}--;`, () => gen.if((0, codegen_1._)`${eql}(${data}[${i}], ${data}[${j}])`, () => {
        cxt.error();
        gen.assign(valid, false).break(outer);
      })));
    }
  }
};
exports.default = def;
