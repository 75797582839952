"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const util_1 = require("../../compile/util");
const def = {
  keyword: "allOf",
  schemaType: "array",
  code(cxt) {
    const {
      gen,
      schema,
      it
    } = cxt;
    /* istanbul ignore if */
    if (!Array.isArray(schema)) throw new Error("ajv implementation error");
    const valid = gen.name("valid");
    schema.forEach((sch, i) => {
      if ((0, util_1.alwaysValidSchema)(it, sch)) return;
      const schCxt = cxt.subschema({
        keyword: "allOf",
        schemaProp: i
      }, valid);
      cxt.ok(valid);
      cxt.mergeEvaluated(schCxt);
    });
  }
};
exports.default = def;
