import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';

import { environment } from '@env';

import { ApiQuery, getQueryString, MemberDocument, ServiceVersion, WorkspaceDocument } from '@core/models';
import jsonpatch from 'json-patch';

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  private readonly postOptions = {
    observe: 'response' as const,
    responseType: 'json' as const,
  };

  constructor(private http: HttpClient) { }

  async getVersion(): Promise<ServiceVersion> {
    return await firstValueFrom(
      this.http.get<ServiceVersion>(environment.adminApiBaseUrl + '/health/version'));
  }

  //
  // Workspaces
  //

  async createWorkspace(content: WorkspaceDocument): Promise<WorkspaceDocument> {
    return await firstValueFrom(
      this.http.post<WorkspaceDocument>(
        environment.adminApiBaseUrl + '/workspaces',
        content));
  }

  async updateWorkspace(
    workspace: WorkspaceDocument): Promise<WorkspaceDocument> {

    return await firstValueFrom(
      this.http.put<WorkspaceDocument>(
        `${environment.adminApiBaseUrl}/workspaces/${workspace.id}`,
        workspace));
  }

  async getWorkspaces(query?: ApiQuery): Promise<[WorkspaceDocument[], string]> {
    return await firstValueFrom(
      this.http
        .get<WorkspaceDocument[]>(
          `${environment.adminApiBaseUrl}/workspaces${getQueryString(query)}`,
        { observe: 'response' })
        .pipe(
          map(response => [response.body || [], response.headers.get('Continuation-Token') || '']))
        );
  }

  async getWorkspace(id: string): Promise<WorkspaceDocument> {
    return await firstValueFrom(
      this.http.get<WorkspaceDocument>(
        `${environment.adminApiBaseUrl}/workspaces/${id}`));
  }

  async patchWorkspace(patch: jsonpatch.OpPatch[], id: string): Promise<WorkspaceDocument> {
    return await firstValueFrom(
      this.http.patch<WorkspaceDocument>(
        `${environment.adminApiBaseUrl}/workspaces/${id}`,
        patch));
  }

  //
  // Members
  //

  async createMember(content: MemberDocument): Promise<MemberDocument> {
    return await firstValueFrom(
      this.http.post<MemberDocument>(
        environment.adminApiBaseUrl + '/members',
        content));
  }

  async updateMember(content: MemberDocument, id: string): Promise<MemberDocument> {
    return await firstValueFrom(
      this.http.put<MemberDocument>(environment.adminApiBaseUrl + `/members/${id}`, content));
  }

  async getMembers(query?: ApiQuery): Promise<[MemberDocument[], string]> {
    return await firstValueFrom(
      this.http
        .get<MemberDocument[]>(
          `${environment.adminApiBaseUrl}/members${getQueryString(query)}`,
          { observe: 'response' })
        .pipe(
          map(response => [response.body || [], response.headers.get('Continuation-Token') || ''])
        )
      );
  }

  async getMember(id: string): Promise<MemberDocument> {
    return await firstValueFrom(
      this.http.get<MemberDocument>(environment.adminApiBaseUrl + `/members/${id}`));
  }

  async patchMember(patch: jsonpatch.OpPatch[], id: string): Promise<MemberDocument> {
    return await firstValueFrom(
      this.http.patch<MemberDocument>(
        environment.adminApiBaseUrl + `/members/${id}`,
        patch));
  }
}
