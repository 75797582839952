"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateSchemaDeps = exports.validatePropertyDeps = exports.error = void 0;
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const code_1 = require("../code");
exports.error = {
  message: ({
    params: {
      property,
      depsCount,
      deps
    }
  }) => {
    const property_ies = depsCount === 1 ? "property" : "properties";
    return (0, codegen_1.str)`must have ${property_ies} ${deps} when property ${property} is present`;
  },
  params: ({
    params: {
      property,
      depsCount,
      deps,
      missingProperty
    }
  }) => (0, codegen_1._)`{property: ${property},
    missingProperty: ${missingProperty},
    depsCount: ${depsCount},
    deps: ${deps}}` // TODO change to reference
};
const def = {
  keyword: "dependencies",
  type: "object",
  schemaType: "object",
  error: exports.error,
  code(cxt) {
    const [propDeps, schDeps] = splitDependencies(cxt);
    validatePropertyDeps(cxt, propDeps);
    validateSchemaDeps(cxt, schDeps);
  }
};
function splitDependencies({
  schema
}) {
  const propertyDeps = {};
  const schemaDeps = {};
  for (const key in schema) {
    if (key === "__proto__") continue;
    const deps = Array.isArray(schema[key]) ? propertyDeps : schemaDeps;
    deps[key] = schema[key];
  }
  return [propertyDeps, schemaDeps];
}
function validatePropertyDeps(cxt, propertyDeps = cxt.schema) {
  const {
    gen,
    data,
    it
  } = cxt;
  if (Object.keys(propertyDeps).length === 0) return;
  const missing = gen.let("missing");
  for (const prop in propertyDeps) {
    const deps = propertyDeps[prop];
    if (deps.length === 0) continue;
    const hasProperty = (0, code_1.propertyInData)(gen, data, prop, it.opts.ownProperties);
    cxt.setParams({
      property: prop,
      depsCount: deps.length,
      deps: deps.join(", ")
    });
    if (it.allErrors) {
      gen.if(hasProperty, () => {
        for (const depProp of deps) {
          (0, code_1.checkReportMissingProp)(cxt, depProp);
        }
      });
    } else {
      gen.if((0, codegen_1._)`${hasProperty} && (${(0, code_1.checkMissingProp)(cxt, deps, missing)})`);
      (0, code_1.reportMissingProp)(cxt, missing);
      gen.else();
    }
  }
}
exports.validatePropertyDeps = validatePropertyDeps;
function validateSchemaDeps(cxt, schemaDeps = cxt.schema) {
  const {
    gen,
    data,
    keyword,
    it
  } = cxt;
  const valid = gen.name("valid");
  for (const prop in schemaDeps) {
    if ((0, util_1.alwaysValidSchema)(it, schemaDeps[prop])) continue;
    gen.if((0, code_1.propertyInData)(gen, data, prop, it.opts.ownProperties), () => {
      const schCxt = cxt.subschema({
        keyword,
        schemaProp: prop
      }, valid);
      cxt.mergeValidEvaluated(schCxt, valid);
    }, () => gen.var(valid, true) // TODO var
    );
    cxt.ok(valid);
  }
}
exports.validateSchemaDeps = validateSchemaDeps;
exports.default = def;
