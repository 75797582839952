"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const items_1 = require("./items");
const def = {
  keyword: "prefixItems",
  type: "array",
  schemaType: ["array"],
  before: "uniqueItems",
  code: cxt => (0, items_1.validateTuple)(cxt, "items")
};
exports.default = def;
