import { Injectable, signal } from '@angular/core';
import { AccessTokenResponse } from '@core/models';
import { AuthApiService, StorageKey, StorageService } from '@core/services';

import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token = '';
  private refreshToken = '';

  readonly isAuthenticated = signal(false);
  readonly isRefreshing = signal(false);

  constructor(
    private authApi: AuthApiService,
    private storageService: StorageService) {
  }

  async login(): Promise<void> {
    try {
      this.isAuthenticated.set(false);
      const response = await this.authApi.getTokenFromCredentials();
      this.setSession(response);
      this.isAuthenticated.set(this.hasValidToken);
    } catch {
      this.logout();
      this.isAuthenticated.set(false);
    }
  }

  logout(): void {
    this.removeSession();
    this.isAuthenticated.set(false);
  }

  async refresh(): Promise<void> {
    await this.reauthenticate();
  }

  getToken(): string {
    return this.token;
  }

  refreshToken$(): Observable<string> {
    const refreshToken = this.getRefreshToken();
    const getToken = !refreshToken
      ? this.authApi.getToken$('client_credentials')
      : this.authApi.getToken$('refresh_token', refreshToken);

      return getToken.pipe(
        tap(response => {
          this.setSession(response);
          this.isAuthenticated.set(this.hasValidToken);
        }),
        map(response => response.access_token),
      );
  }

  private async reauthenticate(): Promise<string> {
    this.isAuthenticated.set(false);
    const refreshToken = this.getRefreshToken();
    const getToken = !refreshToken
      ? await this.authApi.getTokenFromCredentials()
      : await this.authApi.getToken('refresh_token', refreshToken);

    this.setSession(getToken);
    this.isAuthenticated.set(this.hasValidToken);
    return getToken.access_token;
  }

  private getRefreshToken(): string {
    if (!this.refreshToken) {
      this.refreshToken = this.storageService.get(StorageKey.refreshToken) || '';
    }
    return this.refreshToken;
  }

  private get hasValidToken(): boolean {
    return !!this.token && Date.now() < this.Expiration;
  }

  private setSession(response: AccessTokenResponse) {
    // Set expirection time and subtract one minute to give a buffer
    this.token = response.access_token;
    this.refreshToken = response.refresh_token;
    const expires_in = response.expires_in;
    this.storageService.set(StorageKey.expires, Date.now() + (expires_in - 60) * 1000);

    this.storageService.set(StorageKey.accessToken, response.access_token);
    this.storageService.set(StorageKey.tokenType, response.token_type);
    this.storageService.set(StorageKey.refreshToken, response.refresh_token);
  }

  private removeSession(): void {
    this.token = '';
    this.refreshToken = '';
    this.storageService.remove(StorageKey.expires);
    this.storageService.remove(StorageKey.accessToken);
    this.storageService.remove(StorageKey.tokenType);
    this.storageService.remove(StorageKey.refreshToken);
  }

  private get Expiration(): number {
    const expiration = this.storageService.get<string>(StorageKey.expires);
    // Use now as expiration if expiration is null
    return JSON.parse(expiration || Date.now().toString());
  }
}
