import { DateTime } from 'luxon';
import { RecordFields, RecordLink, RecordLinks } from './record-content';
import { Document } from '@core/models';

export interface LatLng {
  lat: number;
  lng: number;
}

export class Location {
  constructor(public id: string, public name: string, public description?: string,
              public address?: string, public city?: string, public region?: string,
              public postalCode?: string, public county?: string,
              public position?: LatLng,
              public creator?: RecordLink, public belongsTo?: RecordLink) {}

  static fromDocument(record: Document): Location {
    const fields = record.content.fields;

    return new Location(
      record.id,
      fields['name'],
      fields['description'],
      this.firstValue(record, 'address'),
      fields['locality'],
      fields['region'],
      fields['postalCode'],
      fields['country'],
      fields['position'],
      this.firstLink(record, 'createdBy'),
      this.firstLink(record, 'belongsTo'));
  }

  toDocument(): Document {
    const fields: RecordFields = {
      subType: 'location',
      state: 'draft',
      createdAt: DateTime.now().toISO(),
      modifiedAt: DateTime.now().toISO(),
      name: this.name,
      description: this.description,
      address: [this.address],
      locality: this.city,
      region: this.region,
      postalCode: this.postalCode,
      country: this.county,
      position: this.position,
      external: false,
    };

    const links: RecordLinks = {};
    if (this.creator) {
      links['owner'] = [ this.creator ];
      links['createdBy'] = [ this.creator ];
      links['modifiedBy'] = [ this.creator ];
    }
    if (this.belongsTo) {
      links['belongsTo'] = [ this.belongsTo ];
    }

    return {
      id: this.id,
      documentId: this.id,
      documentType: 'record',
      content: {
        recordType: 'location',
        fields,
        links,
      },
    };
  }

  private static firstValue(record: Document, field: string): string | undefined {
    return record.content.fields[field] && record.content.fields[field].length > 0
      ? record.content.fields[field][0]
      : undefined;
  }

  private static firstLink(record: Document, link: string): RecordLink | undefined {
    return record.content.links[link] && record.content.links[link].length > 0
      ? record.content.links[link][0]
      : undefined;
  }
}
