export enum DocumentTypes {
  referenceData = 'referenceData',
  translation = 'translation',
  record = 'record',
  recordType = 'recordType',
  dashboard = 'dashboard',
  filter = 'filter',
  workspaceData = 'workspaceData',
  userData = 'userData',
  termsOfUse = 'termsOfUse',
  emailTemplate = 'emailTemplate',
  workspace = 'workspace',
  member = 'member',
  integration = 'integration',
  serviceMember = 'serviceMember',
  serviceAccount = 'serviceAccount',
}

export enum RecordTypes {
  action = 'action',
  audit = 'audit',
  checklist = 'checklist',
  finding = 'finding',
  location = 'location',
  person = 'person',
  serviceAccount = 'serviceAccount',
  update = 'update',
  organisation = 'organisation',
  platformmember = 'platformmember',
  policy = 'policy',
  workspace = 'workspace',
}

export enum LevelPolicy {
  superadmin = 'superadmin',
  admin = 'admin',
  standard = 'standard',
  readonly = 'readonly',
  none = '',
}

export enum DefaultDocuments {
  defaultDashboardTranslationEnGB = 'common/translation.dashboard.default.en-GB',
  defaultDashboardTranslationHiIN = 'common/translation.dashboard.default.hi-IN',
  defaultDashboard = 'common/dashboard.default',
  defaultEmailTemplate = 'common/emailTemplate.default.en-GB',
  defaultGlobalTranslationEnGB = 'common/translation.global.en-GB',
  defaultGlobalTranslationEsES = 'common/translation.global.es-ES',
  defaultGlobalTranslationHiIN = 'common/translation.global.hi-IN',
  defaultRecordTypeTranslationEnGB = 'common/translation.recordType.default.en-GB',
  defaultRecordTypeTranslationhiIN = 'common/translation.recordType.default.hi-IN',
  defaultRecordType = 'common/recordType.default',
  defaultReferenceDataTranslationEnGB = 'common/translation.referenceData.en-GB',
  defaultReferenceDataTranslationEsES = 'common/translation.referenceData.es-ES',
  defaultReferenceDataTranslationHiIN = 'common/translation.referenceData.hi-IN',
  defaultReferenceData = 'common/referenceData.referenceData',
  defaultTermsOfUse = 'common/termsOfUse.default.en-GB',
  defaultTranslation = 'common/translation.default.en-GB',
  defaultUserData = 'common/userData',
  defaultWorkspaceData = 'common/workspaceData',
}

export enum CodeLanguages {
  yaml = 'yaml',
  json = 'json',
}
