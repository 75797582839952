"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const ucs2length_1 = require("../../runtime/ucs2length");
const error = {
  message({
    keyword,
    schemaCode
  }) {
    const comp = keyword === "maxLength" ? "more" : "fewer";
    return (0, codegen_1.str)`must NOT have ${comp} than ${schemaCode} characters`;
  },
  params: ({
    schemaCode
  }) => (0, codegen_1._)`{limit: ${schemaCode}}`
};
const def = {
  keyword: ["maxLength", "minLength"],
  type: "string",
  schemaType: "number",
  $data: true,
  error,
  code(cxt) {
    const {
      keyword,
      data,
      schemaCode,
      it
    } = cxt;
    const op = keyword === "maxLength" ? codegen_1.operators.GT : codegen_1.operators.LT;
    const len = it.opts.unicode === false ? (0, codegen_1._)`${data}.length` : (0, codegen_1._)`${(0, util_1.useFunc)(cxt.gen, ucs2length_1.default)}(${data})`;
    cxt.fail$data((0, codegen_1._)`${len} ${op} ${schemaCode}`);
  }
};
exports.default = def;
