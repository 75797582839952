import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, } from 'rxjs';

import { environment } from '@env';
import { ApiQuery, Document, DocumentContentType, getQueryString, ServiceVersion } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DocumentApiService {

  constructor(private http: HttpClient) { }

  async getVersion(): Promise<ServiceVersion> {
    return await firstValueFrom(
      this.http.get<ServiceVersion>(environment.documentApiBaseUrl + '/health/version'));
  }

  //
  // Record Types
  //

  async createRecordType(recordType: Document, workSpaceId: string): Promise<Document> {
    return await firstValueFrom(
        this.http.post<Document>(
          environment.documentApiBaseUrl + `/${workSpaceId}/recordTypes`,
          recordType,
          { headers: this.getStandardHeaders() }));
  }

  async deleteRecordType(
    recordType: Document,
    workspaceId: string): Promise<Document> {
    const headers = this.getStandardHeaders()
      .set('If-Match', recordType._etag ?? '');

    return await firstValueFrom(
      this.http.delete<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordType.name}`,
        { headers: headers }));
  }

  async getAllRecordTypes(workspaceId: string): Promise<Document[]> {
    return await firstValueFrom(
      this.http.get<Document[]>(
        environment.documentApiBaseUrl + `/${workspaceId}/recordTypes`,
        { headers: this.getStandardHeaders() }));
  }

  async getRecordType(recordTypeName: string, workspaceId: string): Promise<Document> {
    return await firstValueFrom(
      this.http.get<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${recordTypeName}`,
        { headers: this.getStandardHeaders() }));
  }

  async updateRecordType(
    document: Document,
    workspaceId: string): Promise<Document> {

    return await firstValueFrom(
      this.http.put<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/recordTypes/${document.name}`,
        document,
        { headers: this.getStandardHeaders() }));
  }

  //
  // Documents
  //

  async createDocument(document: Document, workspaceId: string): Promise<Document> {
    return await firstValueFrom(
      this.http.post<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents/`,
        document,
        { headers: this.getStandardHeaders() }));
  }

  async deleteDocument(document: Document, workspaceId: string): Promise<Document> {
    const headers = this.getStandardHeaders()
      .set('If-Match', document._etag ?? '');

    return await firstValueFrom(
      this.http.delete<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents/${document.documentId}`,
        { headers: headers }));
  }

  async getAllDocuments(workspaceId: string): Promise<Document[]> {
    return await firstValueFrom(
      this.http.get<Document[]>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents`,
        { headers: this.getStandardHeaders() }));
  }

  async getDocument(id: string, workspaceId: string): Promise<Document> {
    return await firstValueFrom(
      this.http.get<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents/${id}`,
        { headers: this.getStandardHeaders() }));
  }

  async updateDocument(document: Document, workspaceId: string): Promise<Document> {
    return await firstValueFrom(
      this.http.put<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/documents/${document.documentId}`,
        document,
        { headers: this.getStandardHeaders() }));
  }

  //
  // Records
  //

  async createRecord(record: Document, workspaceId: string): Promise<Document> {
    return await firstValueFrom(
      this.http.post<Document>(
        environment.documentApiBaseUrl
          + `/${workspaceId}/records/${record.content.recordType}`,
        record,
        { headers: this.getStandardHeaders() }));
  }

  async deleteRecord(record: Document, workspaceId: string): Promise<Document> {
    const headers = this.getStandardHeaders()
      .set('If-Match', record._etag ?? '');

    return await firstValueFrom(this.http.delete<Document>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${record.content.recordType}/${record.id}`,
      { headers: headers }));
  }

  async getRecord(id: string, recordTypeName: string, workspaceId: string): Promise<Document> {
    return await firstValueFrom(this.http.get<Document>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${recordTypeName}/${id}`,
      { headers: this.getStandardHeaders() }));
  }

  async getRecords(recordTypeName: string, workspaceId: string, query?: ApiQuery): Promise<Document[]> {
    return await firstValueFrom(this.http.get<Document[]>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${recordTypeName}${getQueryString(query)}`,
      { headers: this.getStandardHeaders() }));
  }

  async updateRecord(workspaceId: string, record: Document): Promise<Document> {
    return await firstValueFrom(this.http.put<Document>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${record.content.recordType}/${record.id}`,
      record,
      { headers: this.getStandardHeaders() }));
  }

  async patchRecord(
    workspaceId: string,
    id: string,
    recordTypeName: string,
    patch: unknown): Promise<Document> {
    return await firstValueFrom(this.http.patch<Document>(
      environment.documentApiBaseUrl
        + `/${workspaceId}/records/${recordTypeName}/${id}`,
      patch,
      { headers: new HttpHeaders().set('Accept', DocumentContentType) }));
  }

  //
  // Inheritance
  //

  async createRecordForInheritance(record: Document): Promise<Document> {
    return await firstValueFrom(this.http.post<Document>(
      environment.documentApiBaseUrl + `/inheritance/records`,
      record,
      { headers: this.getStandardHeaders() }));
  }

  async updateRecordForInheritance(record: Document): Promise<Document> {
    return await firstValueFrom(this.http.put<Document>(
      environment.documentApiBaseUrl + `/inheritance/records/${record.id}`,
      record,
      { headers: this.getStandardHeaders() }));
  }


  //
  // Reference Data
  //

  async getReferenceData(workspaceId: string): Promise<Document[]> {
    return await firstValueFrom(this.http.get<Document[]>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents?documentType=referenceData`,
      { headers: this.getStandardHeaders() }));
  }

  //
  // Translation
  //

  async createTranslation(translation: Document, workspaceId: string): Promise<Document> {
    return await firstValueFrom(this.http.post<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/documents/`,
      translation,
      { headers: this.getStandardHeaders() }));
  }

  //
  // Workspace Data
  //

  async getWorkspaceData(workspaceId: string): Promise<Document> {
    return await firstValueFrom(
        this.http.get<Document>(
        environment.documentApiBaseUrl + `/${workspaceId}/workspaceData`,
        { headers: this.getStandardHeaders() }));
  }

  async updateWorkspaceData(workspaceId: string, workspaceData: Document): Promise<Document> {
    return await firstValueFrom(this.http.put<Document>(
      environment.documentApiBaseUrl + `/${workspaceId}/workspaceData`,
      workspaceData,
      { headers: this.getStandardHeaders() }));
  }

  private getStandardHeaders(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', DocumentContentType)
      .set('Accept', DocumentContentType);
  }


}
