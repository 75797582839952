import { Component, computed, effect, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressState } from '@core/models';

export interface ProgressDialogData {
  title: string;
  description: string;
  progressState: ProgressState;
}

@Component({
    selector: 'app-progress-dialog',
    imports: [
        MatDialogModule,
        MatProgressBarModule,
    ],
    templateUrl: './progress-dialog.component.html'
})
export class ProgressDialogComponent{
  data = inject<ProgressDialogData>(MAT_DIALOG_DATA);

  value = computed(() => this.data.progressState.updates().value);
  message = computed(() => this.data.progressState.updates().message);
  isSuccess = computed(() => this.data.progressState.updates().isSuccess);

  constructor(
    private readonly dialogRef: MatDialogRef<ProgressDialogComponent>,
  ) {
    dialogRef.disableClose = true;
    effect(() => {
      if (this.value() >= 100) {
        new Promise(resolve => setTimeout(resolve, this.isSuccess() ? 750 : 1500))
          .then(() => this.dialogRef.close());
      }
    });
  }
}
