"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const additionalItems_1 = require("./additionalItems");
const prefixItems_1 = require("./prefixItems");
const items_1 = require("./items");
const items2020_1 = require("./items2020");
const contains_1 = require("./contains");
const dependencies_1 = require("./dependencies");
const propertyNames_1 = require("./propertyNames");
const additionalProperties_1 = require("./additionalProperties");
const properties_1 = require("./properties");
const patternProperties_1 = require("./patternProperties");
const not_1 = require("./not");
const anyOf_1 = require("./anyOf");
const oneOf_1 = require("./oneOf");
const allOf_1 = require("./allOf");
const if_1 = require("./if");
const thenElse_1 = require("./thenElse");
function getApplicator(draft2020 = false) {
  const applicator = [
  // any
  not_1.default, anyOf_1.default, oneOf_1.default, allOf_1.default, if_1.default, thenElse_1.default,
  // object
  propertyNames_1.default, additionalProperties_1.default, dependencies_1.default, properties_1.default, patternProperties_1.default];
  // array
  if (draft2020) applicator.push(prefixItems_1.default, items2020_1.default);else applicator.push(additionalItems_1.default, items_1.default);
  applicator.push(contains_1.default);
  return applicator;
}
exports.default = getApplicator;
