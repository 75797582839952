"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assignDefaults = void 0;
const codegen_1 = require("../codegen");
const util_1 = require("../util");
function assignDefaults(it, ty) {
  const {
    properties,
    items
  } = it.schema;
  if (ty === "object" && properties) {
    for (const key in properties) {
      assignDefault(it, key, properties[key].default);
    }
  } else if (ty === "array" && Array.isArray(items)) {
    items.forEach((sch, i) => assignDefault(it, i, sch.default));
  }
}
exports.assignDefaults = assignDefaults;
function assignDefault(it, prop, defaultValue) {
  const {
    gen,
    compositeRule,
    data,
    opts
  } = it;
  if (defaultValue === undefined) return;
  const childData = (0, codegen_1._)`${data}${(0, codegen_1.getProperty)(prop)}`;
  if (compositeRule) {
    (0, util_1.checkStrictMode)(it, `default is ignored for: ${childData}`);
    return;
  }
  let condition = (0, codegen_1._)`${childData} === undefined`;
  if (opts.useDefaults === "empty") {
    condition = (0, codegen_1._)`${condition} || ${childData} === null || ${childData} === ""`;
  }
  // `${childData} === undefined` +
  // (opts.useDefaults === "empty" ? ` || ${childData} === null || ${childData} === ""` : "")
  gen.if(condition, (0, codegen_1._)`${childData} = ${(0, codegen_1.stringify)(defaultValue)}`);
}
