"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extendSubschemaMode = exports.extendSubschemaData = exports.getSubschema = void 0;
const codegen_1 = require("../codegen");
const util_1 = require("../util");
function getSubschema(it, {
  keyword,
  schemaProp,
  schema,
  schemaPath,
  errSchemaPath,
  topSchemaRef
}) {
  if (keyword !== undefined && schema !== undefined) {
    throw new Error('both "keyword" and "schema" passed, only one allowed');
  }
  if (keyword !== undefined) {
    const sch = it.schema[keyword];
    return schemaProp === undefined ? {
      schema: sch,
      schemaPath: (0, codegen_1._)`${it.schemaPath}${(0, codegen_1.getProperty)(keyword)}`,
      errSchemaPath: `${it.errSchemaPath}/${keyword}`
    } : {
      schema: sch[schemaProp],
      schemaPath: (0, codegen_1._)`${it.schemaPath}${(0, codegen_1.getProperty)(keyword)}${(0, codegen_1.getProperty)(schemaProp)}`,
      errSchemaPath: `${it.errSchemaPath}/${keyword}/${(0, util_1.escapeFragment)(schemaProp)}`
    };
  }
  if (schema !== undefined) {
    if (schemaPath === undefined || errSchemaPath === undefined || topSchemaRef === undefined) {
      throw new Error('"schemaPath", "errSchemaPath" and "topSchemaRef" are required with "schema"');
    }
    return {
      schema,
      schemaPath,
      topSchemaRef,
      errSchemaPath
    };
  }
  throw new Error('either "keyword" or "schema" must be passed');
}
exports.getSubschema = getSubschema;
function extendSubschemaData(subschema, it, {
  dataProp,
  dataPropType: dpType,
  data,
  dataTypes,
  propertyName
}) {
  if (data !== undefined && dataProp !== undefined) {
    throw new Error('both "data" and "dataProp" passed, only one allowed');
  }
  const {
    gen
  } = it;
  if (dataProp !== undefined) {
    const {
      errorPath,
      dataPathArr,
      opts
    } = it;
    const nextData = gen.let("data", (0, codegen_1._)`${it.data}${(0, codegen_1.getProperty)(dataProp)}`, true);
    dataContextProps(nextData);
    subschema.errorPath = (0, codegen_1.str)`${errorPath}${(0, util_1.getErrorPath)(dataProp, dpType, opts.jsPropertySyntax)}`;
    subschema.parentDataProperty = (0, codegen_1._)`${dataProp}`;
    subschema.dataPathArr = [...dataPathArr, subschema.parentDataProperty];
  }
  if (data !== undefined) {
    const nextData = data instanceof codegen_1.Name ? data : gen.let("data", data, true); // replaceable if used once?
    dataContextProps(nextData);
    if (propertyName !== undefined) subschema.propertyName = propertyName;
    // TODO something is possibly wrong here with not changing parentDataProperty and not appending dataPathArr
  }
  if (dataTypes) subschema.dataTypes = dataTypes;
  function dataContextProps(_nextData) {
    subschema.data = _nextData;
    subschema.dataLevel = it.dataLevel + 1;
    subschema.dataTypes = [];
    it.definedProperties = new Set();
    subschema.parentData = it.data;
    subschema.dataNames = [...it.dataNames, _nextData];
  }
}
exports.extendSubschemaData = extendSubschemaData;
function extendSubschemaMode(subschema, {
  jtdDiscriminator,
  jtdMetadata,
  compositeRule,
  createErrors,
  allErrors
}) {
  if (compositeRule !== undefined) subschema.compositeRule = compositeRule;
  if (createErrors !== undefined) subschema.createErrors = createErrors;
  if (allErrors !== undefined) subschema.allErrors = allErrors;
  subschema.jtdDiscriminator = jtdDiscriminator; // not inherited
  subschema.jtdMetadata = jtdMetadata; // not inherited
}
exports.extendSubschemaMode = extendSubschemaMode;
