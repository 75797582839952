"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateAdditionalItems = void 0;
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const error = {
  message: ({
    params: {
      len
    }
  }) => (0, codegen_1.str)`must NOT have more than ${len} items`,
  params: ({
    params: {
      len
    }
  }) => (0, codegen_1._)`{limit: ${len}}`
};
const def = {
  keyword: "additionalItems",
  type: "array",
  schemaType: ["boolean", "object"],
  before: "uniqueItems",
  error,
  code(cxt) {
    const {
      parentSchema,
      it
    } = cxt;
    const {
      items
    } = parentSchema;
    if (!Array.isArray(items)) {
      (0, util_1.checkStrictMode)(it, '"additionalItems" is ignored when "items" is not an array of schemas');
      return;
    }
    validateAdditionalItems(cxt, items);
  }
};
function validateAdditionalItems(cxt, items) {
  const {
    gen,
    schema,
    data,
    keyword,
    it
  } = cxt;
  it.items = true;
  const len = gen.const("len", (0, codegen_1._)`${data}.length`);
  if (schema === false) {
    cxt.setParams({
      len: items.length
    });
    cxt.pass((0, codegen_1._)`${len} <= ${items.length}`);
  } else if (typeof schema == "object" && !(0, util_1.alwaysValidSchema)(it, schema)) {
    const valid = gen.var("valid", (0, codegen_1._)`${len} <= ${items.length}`); // TODO var
    gen.if((0, codegen_1.not)(valid), () => validateItems(valid));
    cxt.ok(valid);
  }
  function validateItems(valid) {
    gen.forRange("i", items.length, len, i => {
      cxt.subschema({
        keyword,
        dataProp: i,
        dataPropType: util_1.Type.Num
      }, valid);
      if (!it.allErrors) gen.if((0, codegen_1.not)(valid), () => gen.break());
    });
  }
}
exports.validateAdditionalItems = validateAdditionalItems;
exports.default = def;
