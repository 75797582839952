"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const error = {
  message: ({
    schemaCode
  }) => (0, codegen_1.str)`must match format "${schemaCode}"`,
  params: ({
    schemaCode
  }) => (0, codegen_1._)`{format: ${schemaCode}}`
};
const def = {
  keyword: "format",
  type: ["number", "string"],
  schemaType: "string",
  $data: true,
  error,
  code(cxt, ruleType) {
    const {
      gen,
      data,
      $data,
      schema,
      schemaCode,
      it
    } = cxt;
    const {
      opts,
      errSchemaPath,
      schemaEnv,
      self
    } = it;
    if (!opts.validateFormats) return;
    if ($data) validate$DataFormat();else validateFormat();
    function validate$DataFormat() {
      const fmts = gen.scopeValue("formats", {
        ref: self.formats,
        code: opts.code.formats
      });
      const fDef = gen.const("fDef", (0, codegen_1._)`${fmts}[${schemaCode}]`);
      const fType = gen.let("fType");
      const format = gen.let("format");
      // TODO simplify
      gen.if((0, codegen_1._)`typeof ${fDef} == "object" && !(${fDef} instanceof RegExp)`, () => gen.assign(fType, (0, codegen_1._)`${fDef}.type || "string"`).assign(format, (0, codegen_1._)`${fDef}.validate`), () => gen.assign(fType, (0, codegen_1._)`"string"`).assign(format, fDef));
      cxt.fail$data((0, codegen_1.or)(unknownFmt(), invalidFmt()));
      function unknownFmt() {
        if (opts.strictSchema === false) return codegen_1.nil;
        return (0, codegen_1._)`${schemaCode} && !${format}`;
      }
      function invalidFmt() {
        const callFormat = schemaEnv.$async ? (0, codegen_1._)`(${fDef}.async ? await ${format}(${data}) : ${format}(${data}))` : (0, codegen_1._)`${format}(${data})`;
        const validData = (0, codegen_1._)`(typeof ${format} == "function" ? ${callFormat} : ${format}.test(${data}))`;
        return (0, codegen_1._)`${format} && ${format} !== true && ${fType} === ${ruleType} && !${validData}`;
      }
    }
    function validateFormat() {
      const formatDef = self.formats[schema];
      if (!formatDef) {
        unknownFormat();
        return;
      }
      if (formatDef === true) return;
      const [fmtType, format, fmtRef] = getFormat(formatDef);
      if (fmtType === ruleType) cxt.pass(validCondition());
      function unknownFormat() {
        if (opts.strictSchema === false) {
          self.logger.warn(unknownMsg());
          return;
        }
        throw new Error(unknownMsg());
        function unknownMsg() {
          return `unknown format "${schema}" ignored in schema at path "${errSchemaPath}"`;
        }
      }
      function getFormat(fmtDef) {
        const code = fmtDef instanceof RegExp ? (0, codegen_1.regexpCode)(fmtDef) : opts.code.formats ? (0, codegen_1._)`${opts.code.formats}${(0, codegen_1.getProperty)(schema)}` : undefined;
        const fmt = gen.scopeValue("formats", {
          key: schema,
          ref: fmtDef,
          code
        });
        if (typeof fmtDef == "object" && !(fmtDef instanceof RegExp)) {
          return [fmtDef.type || "string", fmtDef.validate, (0, codegen_1._)`${fmt}.validate`];
        }
        return ["string", fmtDef, fmt];
      }
      function validCondition() {
        if (typeof formatDef == "object" && !(formatDef instanceof RegExp) && formatDef.async) {
          if (!schemaEnv.$async) throw new Error("async format in sync schema");
          return (0, codegen_1._)`await ${fmtRef}(${data})`;
        }
        return typeof format == "function" ? (0, codegen_1._)`${fmtRef}(${data})` : (0, codegen_1._)`${fmtRef}.test(${data})`;
      }
    }
  }
};
exports.default = def;
