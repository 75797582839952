"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.boolOrEmptySchema = exports.topBoolOrEmptySchema = void 0;
const errors_1 = require("../errors");
const codegen_1 = require("../codegen");
const names_1 = require("../names");
const boolError = {
  message: "boolean schema is false"
};
function topBoolOrEmptySchema(it) {
  const {
    gen,
    schema,
    validateName
  } = it;
  if (schema === false) {
    falseSchemaError(it, false);
  } else if (typeof schema == "object" && schema.$async === true) {
    gen.return(names_1.default.data);
  } else {
    gen.assign((0, codegen_1._)`${validateName}.errors`, null);
    gen.return(true);
  }
}
exports.topBoolOrEmptySchema = topBoolOrEmptySchema;
function boolOrEmptySchema(it, valid) {
  const {
    gen,
    schema
  } = it;
  if (schema === false) {
    gen.var(valid, false); // TODO var
    falseSchemaError(it);
  } else {
    gen.var(valid, true); // TODO var
  }
}
exports.boolOrEmptySchema = boolOrEmptySchema;
function falseSchemaError(it, overrideAllErrors) {
  const {
    gen,
    data
  } = it;
  // TODO maybe some other interface should be used for non-keyword validation errors...
  const cxt = {
    gen,
    keyword: "false schema",
    data,
    schema: false,
    schemaCode: false,
    schemaValue: false,
    params: {},
    it
  };
  (0, errors_1.reportError)(cxt, boolError, undefined, overrideAllErrors);
}
