"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const util_1 = require("../../compile/util");
const def = {
  keyword: ["then", "else"],
  schemaType: ["object", "boolean"],
  code({
    keyword,
    parentSchema,
    it
  }) {
    if (parentSchema.if === undefined) (0, util_1.checkStrictMode)(it, `"${keyword}" without "if" is ignored`);
  }
};
exports.default = def;
