import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  readonly translationKeyMap = new Map([
    ['members.errors.invalidEmail',
      'Invalid email address'],
    ['members.errors.onlyBSIEmailForSuperadmin',
      'Only a member with a BSI email address can have a superadmin policy'],
    ['members.errors.onlyBSIEmailForAdmin',
      'Only a member with a BSI email address can have the platform admin policy'],
    ['members.errors.workspaceMemberLimitExceeded',
      'You have reached the maximum number of Admin and Standard members for this workspace'],
    ['document.errors.documentInvalidName',
      'The document name is invalid'],
  ]);

  translate(key: unknown): string {

    if (key instanceof Error) {
      key = key.message;
    } else if (key instanceof HttpErrorResponse) {
      key = key.error?.detail;
    }

    return key
      ? this.translationKeyMap.get(key as string) || key as string || 'General error'
      : key as string || 'General error';
  }
}
