"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extendErrors = exports.resetErrorsCount = exports.reportExtraError = exports.reportError = exports.keyword$DataError = exports.keywordError = void 0;
const codegen_1 = require("./codegen");
const util_1 = require("./util");
const names_1 = require("./names");
exports.keywordError = {
  message: ({
    keyword
  }) => (0, codegen_1.str)`must pass "${keyword}" keyword validation`
};
exports.keyword$DataError = {
  message: ({
    keyword,
    schemaType
  }) => schemaType ? (0, codegen_1.str)`"${keyword}" keyword must be ${schemaType} ($data)` : (0, codegen_1.str)`"${keyword}" keyword is invalid ($data)`
};
function reportError(cxt, error = exports.keywordError, errorPaths, overrideAllErrors) {
  const {
    it
  } = cxt;
  const {
    gen,
    compositeRule,
    allErrors
  } = it;
  const errObj = errorObjectCode(cxt, error, errorPaths);
  if (overrideAllErrors !== null && overrideAllErrors !== void 0 ? overrideAllErrors : compositeRule || allErrors) {
    addError(gen, errObj);
  } else {
    returnErrors(it, (0, codegen_1._)`[${errObj}]`);
  }
}
exports.reportError = reportError;
function reportExtraError(cxt, error = exports.keywordError, errorPaths) {
  const {
    it
  } = cxt;
  const {
    gen,
    compositeRule,
    allErrors
  } = it;
  const errObj = errorObjectCode(cxt, error, errorPaths);
  addError(gen, errObj);
  if (!(compositeRule || allErrors)) {
    returnErrors(it, names_1.default.vErrors);
  }
}
exports.reportExtraError = reportExtraError;
function resetErrorsCount(gen, errsCount) {
  gen.assign(names_1.default.errors, errsCount);
  gen.if((0, codegen_1._)`${names_1.default.vErrors} !== null`, () => gen.if(errsCount, () => gen.assign((0, codegen_1._)`${names_1.default.vErrors}.length`, errsCount), () => gen.assign(names_1.default.vErrors, null)));
}
exports.resetErrorsCount = resetErrorsCount;
function extendErrors({
  gen,
  keyword,
  schemaValue,
  data,
  errsCount,
  it
}) {
  /* istanbul ignore if */
  if (errsCount === undefined) throw new Error("ajv implementation error");
  const err = gen.name("err");
  gen.forRange("i", errsCount, names_1.default.errors, i => {
    gen.const(err, (0, codegen_1._)`${names_1.default.vErrors}[${i}]`);
    gen.if((0, codegen_1._)`${err}.instancePath === undefined`, () => gen.assign((0, codegen_1._)`${err}.instancePath`, (0, codegen_1.strConcat)(names_1.default.instancePath, it.errorPath)));
    gen.assign((0, codegen_1._)`${err}.schemaPath`, (0, codegen_1.str)`${it.errSchemaPath}/${keyword}`);
    if (it.opts.verbose) {
      gen.assign((0, codegen_1._)`${err}.schema`, schemaValue);
      gen.assign((0, codegen_1._)`${err}.data`, data);
    }
  });
}
exports.extendErrors = extendErrors;
function addError(gen, errObj) {
  const err = gen.const("err", errObj);
  gen.if((0, codegen_1._)`${names_1.default.vErrors} === null`, () => gen.assign(names_1.default.vErrors, (0, codegen_1._)`[${err}]`), (0, codegen_1._)`${names_1.default.vErrors}.push(${err})`);
  gen.code((0, codegen_1._)`${names_1.default.errors}++`);
}
function returnErrors(it, errs) {
  const {
    gen,
    validateName,
    schemaEnv
  } = it;
  if (schemaEnv.$async) {
    gen.throw((0, codegen_1._)`new ${it.ValidationError}(${errs})`);
  } else {
    gen.assign((0, codegen_1._)`${validateName}.errors`, errs);
    gen.return(false);
  }
}
const E = {
  keyword: new codegen_1.Name("keyword"),
  schemaPath: new codegen_1.Name("schemaPath"),
  // also used in JTD errors
  params: new codegen_1.Name("params"),
  propertyName: new codegen_1.Name("propertyName"),
  message: new codegen_1.Name("message"),
  schema: new codegen_1.Name("schema"),
  parentSchema: new codegen_1.Name("parentSchema")
};
function errorObjectCode(cxt, error, errorPaths) {
  const {
    createErrors
  } = cxt.it;
  if (createErrors === false) return (0, codegen_1._)`{}`;
  return errorObject(cxt, error, errorPaths);
}
function errorObject(cxt, error, errorPaths = {}) {
  const {
    gen,
    it
  } = cxt;
  const keyValues = [errorInstancePath(it, errorPaths), errorSchemaPath(cxt, errorPaths)];
  extraErrorProps(cxt, error, keyValues);
  return gen.object(...keyValues);
}
function errorInstancePath({
  errorPath
}, {
  instancePath
}) {
  const instPath = instancePath ? (0, codegen_1.str)`${errorPath}${(0, util_1.getErrorPath)(instancePath, util_1.Type.Str)}` : errorPath;
  return [names_1.default.instancePath, (0, codegen_1.strConcat)(names_1.default.instancePath, instPath)];
}
function errorSchemaPath({
  keyword,
  it: {
    errSchemaPath
  }
}, {
  schemaPath,
  parentSchema
}) {
  let schPath = parentSchema ? errSchemaPath : (0, codegen_1.str)`${errSchemaPath}/${keyword}`;
  if (schemaPath) {
    schPath = (0, codegen_1.str)`${schPath}${(0, util_1.getErrorPath)(schemaPath, util_1.Type.Str)}`;
  }
  return [E.schemaPath, schPath];
}
function extraErrorProps(cxt, {
  params,
  message
}, keyValues) {
  const {
    keyword,
    data,
    schemaValue,
    it
  } = cxt;
  const {
    opts,
    propertyName,
    topSchemaRef,
    schemaPath
  } = it;
  keyValues.push([E.keyword, keyword], [E.params, typeof params == "function" ? params(cxt) : params || (0, codegen_1._)`{}`]);
  if (opts.messages) {
    keyValues.push([E.message, typeof message == "function" ? message(cxt) : message]);
  }
  if (opts.verbose) {
    keyValues.push([E.schema, schemaValue], [E.parentSchema, (0, codegen_1._)`${topSchemaRef}${schemaPath}`], [names_1.default.data, data]);
  }
  if (propertyName) keyValues.push([E.propertyName, propertyName]);
}
