"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const codegen_1 = require("../../compile/codegen");
const util_1 = require("../../compile/util");
const error = {
  message: ({
    params
  }) => (0, codegen_1.str)`must match "${params.ifClause}" schema`,
  params: ({
    params
  }) => (0, codegen_1._)`{failingKeyword: ${params.ifClause}}`
};
const def = {
  keyword: "if",
  schemaType: ["object", "boolean"],
  trackErrors: true,
  error,
  code(cxt) {
    const {
      gen,
      parentSchema,
      it
    } = cxt;
    if (parentSchema.then === undefined && parentSchema.else === undefined) {
      (0, util_1.checkStrictMode)(it, '"if" without "then" and "else" is ignored');
    }
    const hasThen = hasSchema(it, "then");
    const hasElse = hasSchema(it, "else");
    if (!hasThen && !hasElse) return;
    const valid = gen.let("valid", true);
    const schValid = gen.name("_valid");
    validateIf();
    cxt.reset();
    if (hasThen && hasElse) {
      const ifClause = gen.let("ifClause");
      cxt.setParams({
        ifClause
      });
      gen.if(schValid, validateClause("then", ifClause), validateClause("else", ifClause));
    } else if (hasThen) {
      gen.if(schValid, validateClause("then"));
    } else {
      gen.if((0, codegen_1.not)(schValid), validateClause("else"));
    }
    cxt.pass(valid, () => cxt.error(true));
    function validateIf() {
      const schCxt = cxt.subschema({
        keyword: "if",
        compositeRule: true,
        createErrors: false,
        allErrors: false
      }, schValid);
      cxt.mergeEvaluated(schCxt);
    }
    function validateClause(keyword, ifClause) {
      return () => {
        const schCxt = cxt.subschema({
          keyword
        }, schValid);
        gen.assign(valid, schValid);
        cxt.mergeValidEvaluated(schCxt, valid);
        if (ifClause) gen.assign(ifClause, (0, codegen_1._)`${keyword}`);else cxt.setParams({
          ifClause: keyword
        });
      };
    }
  }
};
function hasSchema(it, keyword) {
  const schema = it.schema[keyword];
  return schema !== undefined && !(0, util_1.alwaysValidSchema)(it, schema);
}
exports.default = def;
