import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare global {
  interface Window {
    monacoEditorAlreadyInitialized: boolean;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    require: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MonacoEditorService {
  public loadingFinished = new BehaviorSubject<boolean>(false);

  private finishLoading() {
    (window as Window).monacoEditorAlreadyInitialized = true;
    this.loadingFinished.next(true);
  }

  public load() {
    // load the assets

    const baseUrl = './assets/monaco-editor/min/vs';

    if ((window as Window).monacoEditorAlreadyInitialized) {
      this.finishLoading();
      return;
    }

    const onGotAmdLoader = () => {
      // load Monaco
      (window as Window).require.config({ paths: { vs: `${baseUrl}` } });
      (window as Window).require(['vs/editor/editor.main'], () => {
        this.finishLoading();
      });
    };

    // load AMD loader, if necessary
    if (!(window as Window).require) {
      const loaderScript: HTMLScriptElement = document.createElement('script');
      loaderScript.type = 'text/javascript';
      loaderScript.src = `${baseUrl}/loader.js`;
      loaderScript.addEventListener('load', onGotAmdLoader);
      document.body.appendChild(loaderScript);
    } else {
      onGotAmdLoader();
    }
  }
}
